<template>
  <base-table :columns="tableHead" :list="tableData" class="backup-table standart-text">
    <base-table-row v-for="item in tableData" :key="item.key" :columns="tableHead" :item="item">
      <template #interval>
        {{ item.interval }}
      </template>
      <template #date>
        {{ item.date }}
      </template>
      <template #copies>
        {{ item.copies }}
      </template>
      <template #state>
        {{ item.state }}
      </template>
      <template #system>
        {{ item.system }}
      </template>
      <template #status>
        {{ item.status }}
      </template>
      <template #action>
        <plain-button icon="more" />
      </template>
    </base-table-row>
  </base-table>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
export default {
  name: 'BackupTable',
  components: { BaseTable, BaseTableRow },
  data() {
    return {
      tableHead: [
        {
          key: 'interval',
          label: this.$t('interval'),
          style: {
            width: '136px',
            minWidth: '120px',
          },
        },
        {
          key: 'date',
          label: this.$t('date'),
          style: {
            width: '180px',
            minWidth: '160px',
          },
        },
        {
          key: 'copies',
          label: this.$t('copies'),
          style: {
            width: '96px',
          },
        },
        {
          key: 'state',
          label: this.$t('state'),
          style: {
            width: '145px',
          },
        },
        {
          key: 'system',
          label: this.$t('system'),
          style: {
            width: '116px',
          },
        },
        {
          key: 'status',
          label: this.$t('status'),
          style: {
            width: '60px',
          },
        },
        {
          key: 'action',
          style: {
            width: '40px',
          },
        },
      ],
      tableData: [
        {
          interval: 'Каждый день',
          date: '28.06.2020 15:37:51',
          copies: 1,
          state: 'Запланирован',
          system: false,
          status: 'Включён',
        },
        {
          interval: 'По пятницам',
          date: '28.06.2020 15:37:51',
          copies: 1,
          state: 'Запланирован',
          system: false,
          status: 'Включён',
        },
      ],
    };
  },
};
</script>

<i18n>
  {
    "ru": {
      "interval": "Интервал",
      "date": "Следующая дата",
      "copies": "Копий",
      "state": "Состояние",
      "system": "Система",
      "status": "Статус"
    }
  }
</i18n>
